footer {
  .footer-section-secondary {

    background-color: #f0f0f0;
    padding: 15px;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    a {
      text-decoration: none;
    }
    > div {
      justify-content: space-between;
      padding-left: 15px;
      padding-right: 15px;
      flex-wrap: wrap;
      display: flex;
      align-items: flex-start;
      span {
        font-size: 90%;
        margin-right: 15px;
      }
      .inner {
        padding: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        li {
          max-width: 130px;
          text-align: center;
          display: flex;
          padding: 0;
          flex-direction: column;
          margin-right: 10px;
          @media (min-width: 768px) {
            margin-right: 20px;
          }
          p {
            color: #898b92;
            font-size: 14px;
            margin-bottom: 0;
            margin-top: 15px;
          }
        }
      }

    }
  }
  .footer-section-primary{
    @media print {
      display: none;
    }
    background-color: $green3;
    display: flex;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 15px;
    @media all and (max-width: 767px)  {
      padding-top:15px; padding-bottom:15px;
    }

    .logo2 {
      position: absolute;
      display: flex;
      padding:0; margin:0; width:124px; right: 0;
      top: -60px;
      img{
        padding:0; margin:0;
      }
      @media all and (max-width: 767px)  {
        position: unset; top:unset; left:unset; transform: none;
      }
    }
  }
  .footer-menu-holder-primary{
    justify-content: flex-end;
    display: flex;

    .mobile-footer-menu {
      display: none;
    }

    @media all and (max-width: 767px)  {
      flex-direction: row;
      justify-content: space-between;
      .mobile-footer-menu {
        display: block;
        ul {
          padding-left: 0;
          list-style: none;
          margin-top: 0;
          li a {
            color: #fff;
            font: 400 14px/normal Arial,Helvetica,sans-serif;
          }
        }
      }
    }
    .footer-column-primary{
      @media all and (min-width: 1024px){
        margin-right: 15px;
      }
      position: relative;
      width:calc((100% - 75px) / 6);
      @media all and (max-width: 1200px) {
        width:calc((100% - 75px) / 4);
      }
      @media all and (max-width: 900px) {
        width:calc((100% - 75px) / 3);
      }
      @media all and (max-width: 767px)  {
        width: auto;
        margin: 0 0 5px  0;
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          display: none;
        }
        .logo2 {
          max-width: 100%;
          display: inline-block;
          img {
            margin-top: -20px;
          }
        }
      }



      &:last-of-type{
        margin:0;
        &.logo2 {
          margin-top: -15px;
        }
      }
      ul {
        list-style: none;
        padding: 0;
        li {
          padding: 0; margin: 0 0 10px 0;
          &:last-of-type{margin:0;}
          a {
            padding:0 ; margin: 0; color: #fff; font: 400 18px/normal 'Arial', 'Helvetica', sans-serif;
          }
          &:hover {
            a {
              color: #d5d929;
            }
          }
        }
      }
    }
  }

}

#cookie-notice {
  color: #fff;
  font-family: inherit;
  background: #005325;
  padding: 3px;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
  margin: 0;
  visibility: hidden;
  z-index: 1000000;
  line-height: 200%;
}

#cookie-notice button, #cookie-notice .button {
  color: inherit;
  background: #97bf0d;
  border: 0;
  padding: 6px;
  cursor: pointer;
  border-radius: 2px;
}

@media all and (max-width: 600px) {
  #cookie-notice {
    max-width: 100%;
    bottom: 0;
    left: 0;
    border-radius: 0
  }
}