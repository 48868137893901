.wp-block-column .block-fortbildung a {
  color: black;
}

.block-fortbildung {
  > a {
    display: block;
    padding: 15px 10px 20px 10px;
    background-color: white;
    color: black;
    font-size: 16px;
    text-decoration: none;
  }

  h3, h3 p {
    margin: 0;
    font-size: 18px;
    line-height: 1.2rem;
  }

  hr {
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: black;
  }

  h4 {
    color: black;
    margin: 0 0 20px 0;
    font-weight: 600;
    font-size: 18px;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 9px;
      border-color: transparent transparent transparent black;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      margin-bottom: 4px;
    }
  }

  .fortbildung__vortragender {
    font-style: italic;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .fortbildung__datum {
    font-style: italic;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .fortbildung__dauer {
    font-size: 14px;
  }

  .fortbildung__dauer-clock {
    font-size: 90%;
    vertical-align: text-bottom;
  }

  .fortbildung__text {
    hyphens: auto;
  }

  .fortbildung__zielgruppe {
    font-size: 15px;
  }

  button {
    margin-top: 15px;
    color: white;
    background-color: $green3;
    border: 0;
    padding: 8px 6px;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    width: 120px;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 0 5px 8px;
      border-color: transparent transparent transparent white;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }

  img {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: 120px;
  }

  footer {
    color: $green3;
    display: flex;
    flex-flow: row;
    align-items: flex-end;

    > div {
      flex: 1;
      > img {
        float: right;
      }
    }
  }

}