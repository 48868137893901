@import "variables";
@import 'footer';
@import 'product';
@import 'posts';
@import 'exitIntentPopup';
@import "fixes";

#crossclass {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  cursor: pointer;

  svg {
    fill: #FFF;
  }
}

a, button {
  transition: all ease-out 0.5s;
  -webkit-transition: all ease-out 0.5s;
}

a {
  color: #007836;
  overflow-wrap: break-word;
}

a:hover, button:hover {
  text-decoration: none;
  transition: all ease-out 0.5s;
  -webkit-transition: all ease-out 0.5s;
}

tr:nth-child(odd) {
  background-color: white;
}

table {
  @media all and (min-width: 768px) {
    width: 100%;
  }
  height: auto !important;
}

@media all and (max-width: 599px) {
  td, th {
    min-width: 250px;
  }
}

em a {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
  font-style: normal;
  vertical-align: middle;
  word-break: normal;
}

@media (max-width: 768px) {
  figure.wp-block-table {
    margin-left: 0;
    margin-right: 0;
  }
}

td {
  border: 1px solid gray;
  padding: 0 5px;
  border-collapse: collapse;
  border-radius: 2px;
}

iframe {
  max-width: 100%;
}

div.alignnone {
  width: auto !important; // overwrite inline css of img divs.
}

.last-margin > *:last-child {
  margin-bottom: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.width-100-parcent {
  width: 100%;
}

.height-100-parcent {
  height: 100%;
}

.list-style-type-none {
  list-style-type: none;
}

.mobile-view {
  display: none;
}

.dispay-none {
  display: none;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}

.background-wrapper {
  background: #f1f7f3;
  -webkit-print-color-adjust: exact;
  padding-top: 30px;
  padding-bottom: 60px;
  @media all and (min-width: 500px) {
    margin-left: 15px;
    margin-right: 15px;
  }
  margin-bottom: 30px;
}

@media all and (min-width: 768px) {
  .background-wrapper {
    padding-top: 30px;
  }
}

.page h1 {
  color: #007836;
  hyphens: auto;
  font-size: 30px;
  @media all and  (min-width: 1024px) {
    font-size: 45px;
  }
  margin-top: 0px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media (min-width: 768px) {
  .container {
    width: 738px;
  }
}

@media all and (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media all and (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media all and (min-width: 1400px) {
  .container {
    width: calc(100% - 100px);
    max-width: 1500px;
  }
}

.content div {
  max-width: 100%; // fixes images being too wide
}

.narrow-section-primary {
  width: 70%;
  margin: auto;
}

.tertiary-list-style ul li {
  padding: 0;
  margin: 0 0 5px 0;
}

.goleft {
  display: flex !important;
}

.menu-item-has-children span {
  float: right;
}

.green-section-primary {
  background-color: $lightgreen;
  margin-bottom: 30px;
  display: flex;
  padding: 15px;

  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;

    img {
      padding: 0;
      margin: 0;
      width: 100%;
      height: auto;
    }

    @media (max-width: 768px) {
      width: 100%;
      margin: 0 0 15px 0;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .green-section-textholder {
    align-self: flex-start;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    color: #FFF;
    width: calc(100% - (41.51651651651652% + 50px));

    a {
      color: #FFF;
    }

    p {
      &:first-child {
        margin-bottom: 0px;
      }

      padding: 0;
      margin: 0 0 15px 0;
    }

    @media all and (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }
    // h2 {
    //   font-weight: 700;
    //   margin-bottom: 10px;
    //   font-size: 24px;
    // }
    h3 {
      margin-top: 0px;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 30px;
    }
  }

}

.navigation {
  a {
    color: #007836;
  }
}

.psearch {
  background: #f1f7f3;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;

  h1 {
    font-size: 35px;
  }

  .produktsuche {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 60px;

    .input-wrapper {
      width: 33%;

      .inner {
        max-width: 350px;
        @media (max-width: 1200px) {
          max-width: 250px;
        }
        position: relative;

        &:before {
          position: absolute;
          content: " ";
          top: 22px;
          right: 20px;
          z-index: 100;
          width: 0;
          height: 0;
          border: 8px solid transparent;
          border-color: #fff transparent transparent transparent;
        }
      }

      @media all and (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0px;
      }
      display: flex;
      flex-direction: column;

      select {
        width: 100%;
        display: block;
        box-sizing: border-box;
        background-color: #007836;
        height: 50px;
        z-index: 1;
        color: #FFF;
        border: none;
        //margin-top: 20px;
        overflow: visible;
        font-size: 20px;
        outline: none;
        padding: .6em 1.4em .5em .8em;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        position: relative;
        @media all and (max-width: 1200px) {
          font-size: 16px;
        }

        option {
          &:checked {
            background-color: #fff !important;
            color: #000;
          }
        }

      }
    }

    input {
      border: 1px solid #888;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 20px;
    }
  }
}

.overview-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style: none;

  .product-wrapper {
    width: 33.33%;
    @media (max-width: 768px) {
      width: 100%;
    }

    &:nth-child(3n+2) {
      display: flex;
      justify-content: center;
    }

    &:nth-child(3n+3) {
      display: flex;
      justify-content: flex-end;
    }
  }

}

.bild-titel-kachel {
  //min-height: 350px;
  width: 350px;
  max-width: 100%;
  margin-top: 30px;
  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }

  .bild-wrapper {
    width: 350px;
    height: 350px;
    max-width: 100%;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }

  a {
    text-decoration: none;
    h3 {
      color: #007836;
    }
  }

  h3 {
    font-size: 130%;
  }
}

.wp-block-column a, .wp-block-column a:hover, .wp-block-column a:visited {
  color: #007836;
}

.wp-block-image img {
  height: auto;
}

.wp-caption-text {
  padding-top: 0;
  margin: 0;
  /*
  margin: -5px 10px 0 0px;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid black;
   */
  font-style: italic;
  font-size: 16px;
}

.page-template-default h1, .category h1 {
  font-size: 35px;
}

.page-template-default h2 {
  font-size: 23px;
}

.underline {
  a {
    text-decoration: underline;
  }
}

.stellen-title {
  display: flex;
  justify-content: space-between;

  img {
    max-width: 200px;
    width: auto;
    height: auto;
  }
}

.page-template {
  a {
    &.back {
      color: $grey;
      font-size: 13px;
      margin-top: 40px;
      display: inline-block;
    }
  }
}

.breadcrumbs-grey {
  margin-bottom: 30px;
  font-size: 13px;
  color: $grey;

  span {
    a {
      color: $grey;
      font-size: 13px;
    }
  }
}

iframe {
  @media print {
    display: none;
  }
}

.footer-grayrow-primary > div {
  width: calc((100% - 45px) / 4);
}

.footer-grayrow-secondary {
  justify-content: space-between;
  padding: 0px;
  text-align: center;
  color: #898b92;
  font-size: 14px;

  li {
    max-width: 120px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    text-align: left;
    > div {
      width: 100%;
      margin: 0 0 5px 0;
    }
    > div:last-of-type {
      margin: 0;
    }
  }
}

.unternehmen-section-primary {
  .wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;

    .box {
      :not(:last-child) {
        margin-right: 15px;
      }

      width: calc((100% - 60px) / 3);
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      img {
        width: 100%;
        max-width: 100%;
        vertical-align: top;
        height: auto;
      }

      @media (max-width: 1200px) {
        width: 48%;
        margin-right: 0px;
        margin-bottom: 20px;
      }
      @media (max-width: 768px) {
        width: 100%;
      }

      a {
        color: #000;
        width: 100%;

        span {
          margin-top: 10px;
          display: inline-block;
        }
      }
    }
  }
}

.wissen-forschung-section-primary, .service-section-primary {
  display: flex;
  justify-content: space-between;

  .textholder-primary {
    width: calc(100% - (30.07582139848357% + 50px));
    @media (max-width: 768px) {
      width: 100%;
      margin: 0 0 15px 0;
    }
  }

  .img {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    margin-left: 50px;

    img {
      width: 100%;
      max-width: 100%;
    }

    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }

  h2, h3 {
    padding: 0px;
    font-weight: 700;
  }

  h2 {
    margin: 0 0 25px 0;
  }

  h3 {
    margin: 0 0 10px 0;
    font-size: 120%;
  }

  a {
    color: #000;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0 0 30px 0;
  }
}

.service-section-primary {
  margin-top: 60px;
  margin-bottom: 60px;
}

.home-banner-holder {
  @media print {
    display: none;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.product-image-holder-primary {
  height: 230px;
}

.product-image-holder-primary img {
  max-width: 100%;
  height: auto;
}

.product-row-primary ul li:hover a span {
  color: $lightgreen;
}

.product-image-holder-primary + span {
  text-align: center;
}

@media (max-width: 1024px) {
  .product-image-holder-primary + span:nth-of-type(1) {
    font-size: 18px;
  }
  .product-image-holder-primary + span:nth-of-type(2) {
    font-size: 12px;
  }
  .primary-list-style ul li {
    word-break: break-all;
  }
}

.image-holder-secondary {
  width: 30.07582139848357%;
}

.image-holder-secondary img {
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
}

.image-holder-tertiary {
  width: 100%;
}

.image-holder-tertiary img {
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
}

@media (max-width: 767px) {

  .three-image-holder-primary {
    flex-direction: column;
  }

  .narrow-section-primary {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .footer-grayrow-primary {
    flex-direction: column;
    margin: 0 0 30px 0;
  }
  .footer-grayrow-primary > div {
    width: 100%;
    margin: 0 0 5px 0;
  }
  .footer-grayrow-primary > div:last-of-type {
    margin: 0;
  }

  .image-holder-quaternary {
    justify-content: flex-start;
  }
  .menu-row-primary {
    display: none;
  }

  .narrow-section-primary {
    flex-direction: column;
  }
  .newslatter-panel-primary {
    width: 100%;
    margin: 0 0 15px 0;
  }
  .newslatter-panel-image-primary {
    width: 100%;
    max-width: 100%;
  }
}

.suchergebnisse-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .kachel {
    width: 33%;

    .text {
      max-width: 350px;
      width: 100%;
      @media all and (max-width: 1200px) {
        max-width: 250px;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0px;
    }

    h2 {
      margin-top: 0px;
    }

    a {
      color: #007836;
    }

    .image {
      background-color: #FFF;
      height: 350px;
      @media all and (max-width: 1200px) {
        height: 250px;
      }
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      img {
        max-width: 100%;
        max-height: 340px;
        margin-bottom: 0px;
        @media all and (max-width: 1200px) {
          height: 240px;
        }
      }
    }

  }
}

.page-template-template-product .magazin-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0;
}

.magazin-wrapper {
  button {
    padding: 15px;
    color: white;
    background-color: #007836;
    border: 0;
    margin: 10px 0;
    font-size: 19px;
  }

  .clever_fehlermeldung {
    display: none;
    font-weight: bold;
    color: green;
  }

  .clever_form_error .clever_fehlermeldung {
    display: inherit;
  }
}

.magazin-signup input[type=email], .magazin-signup input[type=text] {
  &.clever_form_error {
    border-bottom: 2px solid red;
  }
}

.magazin-signup {
  @media all and (min-width: 768px) {
    display: flex;
    flex-direction: row;

    > * {
      &:nth-child(1) {
        width: calc(100% / 3 * 2);
        margin-right: 0.5rem;
      }

      &:nth-child(2) {
        width: calc(100% / 3 * 1);
        margin-left: 0.5rem;
        background-color: white;
        text-align: center;

        > img {
          margin: 25px auto;
        }
      }
    }

  }

  input[type=email], input[type=text] {
    display: block;
    width: 100%;
    border: 1px solid gray;
    margin: 5px 0;
    font-size: 21px;
    padding: 5px;
  }
}

#mc_embed_signup {
  margin-bottom: 20px;
}

/* .newslatter-panel-image-primary{max-width: 360px;}
.newslatter-panel-image-primary img{width: 100%; height:auto;}
.newslatter-panel-primary{width:calc(100% - 360px);}

.input-style-secondary::-webkit-input-placeholder {color: #7f7f7f}
.input-styel-secondary::-moz-placeholder {color: #7f7f7f;}
.input-styel-secondary:-ms-input-placeholder {color: #7f7f7f;}
.input-styel-secondary:-moz-placeholder { color: #7f7f7f;} */

/* .checkbox-radio-holder-primary label input{opacity: 0; width: 100%; height: 100%; left: 0; top:0;}

.checkbox-radio-holder-primary label input[type="checkbox"] + .proxy-primary{width:20px; height:20px; background-color: #fff; border:1px solid #a7a7a8;}
.checkbox-radio-holder-primary label input[type="checkbox"]:checked + .proxy-primary{background-color: #a7a7a8;}
.checkbox-text-primary{width:calc(100% - 30px);} */

img.alignright {
  float: right;
  margin: 0 0 1em 1em;
}

img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

figcaption {
  font-size: 80%;
  color: gray;
}

.alignright {
  float: right;
}

.alignleft {
  float: left;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media all and (max-width: 768px) {
  table {
    overflow: hidden;
    overflow-x: auto;
    display: block;
    border: 0 !important;

    .wp-block-table {
      margin: 0;
    }
  }
}

@media all and (max-width: 1050px) {
  img.size-large {
    max-width: 100%;
    height: auto;
  }
}

@media all and (max-width: 400px) {
  img.size-large, img.size-medium {
    max-width: calc(100% + 30px);
    height: auto;
    margin-left: -15px;
  }
}


.green-tag-in-corner {
  color: white;
  background-color: #007836;
  display: block;
  float: right;
  padding: 7px 10px;
  font-size: 15px;
}

// Karriere Page
.style-align-column-bottom {
  align-self: flex-end;
}

.style-add-top-margin-25 {
  margin-block-start: 25px;
}

@import 'blocks';
@import 'category';
@import "editor";