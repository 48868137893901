@import "variables";

.page-template-template-product, .page-template-template-subproduct {
  table {
    border-collapse: collapse;
  }

  td {
    padding: 8px;
  }
  .background-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  table {
    border-radius: 2px;
  }

  .product-row-secondary {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
    margin-top: 30px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      height: 230px;

      li {
        height: 100%;
        text-align: left;
        margin-right: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media all and (max-width: 768px) {
          margin-right: 0;
          width: 50%;
        }
        a {
          height: 100%;
          width: 100%;
        }
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
      @media all and (max-width: 992px) {
        height: auto;
        flex-wrap: wrap;
        li {
          margin-right: 0;
          width: 50%;
          height: 200px;
          text-align: center;
        }
      }
      @media all and (max-width: 768px) {
        li {
          margin-right: 0;
          width: 100%;
          height: auto;
          text-align: center;
          justify-content: center;
        }
      }
    }
  }
  .logo_product {
    max-width: 185px;
    height: auto;
    img {
      max-width: 100%;
      height: auto;
    }
    margin-bottom: 15px;
  }
  .content {
    h1 {
      margin: 0;
      font-size: 30px;
      @media all and (min-width: 768px) {
        font-size: 45px;
      }
      margin-bottom: 30px;
      font-weight: 700;
      padding: 0;
    }
    h2 {
      font-weight: 700;
      font-size: 30px;
      margin-bottom: 15px;
      @media all and (max-width: 767px) {
        font-size: 24px;
      }
    }
    h3 {
      font-size: 24px;
      @media all and (max-width: 767px) {
        font-size: 20px;
      }
    }
    h5 {
      font-weight: 300;
      color: $grey;
    }
    .stories {
      ul {
        @media all and (max-width: 768px) {
          padding: 0 0 0 20px;
        }
        @media (min-width: 768px) {
          columns: 300px 2;
          width: fit-content;
          padding: 0;
        }
        li {
          list-style: disc;
          padding: 0;
          font-weight: 300;
          a, &::marker {
            color: $green3;
          }
          &:before {
            display: none;
          }
        }
      }
    }

    ul {
      li {
        list-style: none;
        padding: 0 0 0 30px;
        margin: 0 0 5px 0;
        position: relative;

        &:before {
          padding: 0;
          margin: 0;
          width: 10px;
          height: 10px;
          background-color: #000;
          border-radius: 100%;
          position: absolute;
          left: 0;
          top: 5px;
          content: '';
        }
      }
      &.informationen, &.gebrauchsinformation {
        padding: 0;
        li {
          padding: 0;
          a {
            padding-left: 60px;
            &:before {
              display: block;
              content: "(PDF)";
              width: 100px;
              background-color: transparent;
              font-size: 18px;
              position: absolute;
              top: -1px;

              left: 0;
            }
          }
          &:before {
            display: none;

          }
        }
      }

      &.gebrauchsinformation {
        padding: 0;
        margin: 0 0 5px 0;
        a {
          padding: 0 0 0 30px;
          margin: 0;
          position: relative;
          &:before {
            margin: 0 15px 0 0;
            width: 0;
            height: 0;
            content: '▼';
            position: absolute;
            left: 0;
            top: 5px;
            font-size: 12px;
          }
        }
      }

    }
  }

  .breadcrumbs {
    margin: auto;
    margin-bottom: 15px;
    ul {
      list-style: none;
      padding: 0px;
      display: flex;
      flex-wrap: wrap;
      margin: 0px;
      margin-bottom: 0 0 -5px 0;
    }
    li {
      padding: 0;
      margin: 0 15px 5px 0;
      &:last-of-type {
        margin: 0;
      }
      a {
        padding: 4px 8px;
        @media all and (min-width: 768px) {
          padding: 5px 10px;
        }
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $traumeel;
        color: #fff;
        font-size: 12px;
        text-decoration: none;
        @media all and (min-width: 768px) {
          font-size: 16px;
        }
      }
      &:hover {
        a {
          background-color: $background_color_2;
          color: #fff;
        }
      }
    }
  }
}

.gebrauchsinformationen {
  ul {
    padding: 0px;
    li {
      padding: 0px;
      a {
        padding-left: 35px;
        &:before {
          display: block;
          content: "(PDF)";
          width: 100px;
          background-color: transparent;
          font-size: 18px;
          position: absolute;
          top: -1px;

          left: 0px;
        }
      }
      &:before {
        display: none;

      }
    }
  }
}
