.post-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  margin-top: 60px;
  flex-wrap: wrap;
  .text {
    width: calc(100% - 460px);

    margin-right: 60px;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-right: 0px;
    }
    @media print {
      width: calc(100% - 400px);
      margin-right: 0px;
    }
    a {
      color: #000;
    }
    .link {
      a {
        color: $grey;
        font-size: 14px;
        position: relative;
        margin-left: 16px;
        &:before {
          content: "►";
          color: $grey;
          position: absolute;
          left: -16px;
          top: 50%;
          transform:translateY(-50%);
        }

      }
      @media only screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
    .meta {
      color:$grey;
      font-size: 13px;
      padding: 0px;
      margin: 0px;
    }
  }
  .image {
    max-width: 400px;
    img {
      max-width: 100%;
    }
    @media only screen and (max-width: 768px) {
      max-width: 100%
    }
  }

}
