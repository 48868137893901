.category h1, h3 {
  color: #007836;
}

.img-text-col-container {
  display: flex;
}

.img-text-col-container div {
}

.img-text-img {
  margin-right: 25px;
  border-radius: 50%;
}

@media all and (max-width: 767px) {
  .img-text-col-container {
    flex-direction: column;
  }
  .img-text-img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

@media all and (min-width: 768px) {
  .img-text-col-container div:first-of-type {
    margin-right: 120px;
  }
}

ul.category-child-list {
  list-style: none;
  padding: 0px;
  margin-left: 0px;
  li {
    margin: 0px;
    padding: 5px;
    width: fit-content;
    margin-right: 20px;
    margin-bottom: 12px;
    a {
      display: inline-block;
      text-decoration: none;
      color: #FFF;
    }
  }
}

.category-child-list li {
  background-color: #007836;
  font-size: 90%;
}

.presse-social {
  img {
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }
}

@media all and (min-width: 768px) {
  .presse-social {
    padding-top: 20px;
  }
}