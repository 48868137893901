.ratgeber-pdf-download {
  clear: both;
  margin: 25px 0;
  display: flex;

  @media all and (max-width: 768px) {
    flex-direction: column;
  }

  .ratgeber-block-textblock {
    flex: 3;
    margin-bottom: 30px;
  }

  .ratgeber-block-bildblock {
    flex: 1;
    text-align: center;
  }

  .ratgeber-block-textblock a:first-child {
    color: inherit;
  }

  .ratgeber-bestellen-bild {
    @media all and (max-width: 767px) {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    @media all and (min-width: 768px) {
      margin-left: 50px;
    }

    @media all and (min-width: 1200px) {
      margin-left: 150px;
      margin-right: 150px;
    }
  }

  .ratgeber-bestellen-link {
    left: 22px;
    position: relative;
    color: grey;
    font-size: 70%;
  }

  .ratgeber-bestellen-link:before {
    content: "";
    border-color: transparent grey;
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.55em;
    display: block;
    height: 0;
    width: 0;
    left: -1em;
    top: 1.2em;
    position: relative;
  }
}

@import "block-fortbildung";