body .is-layout-flex {
  flex-wrap: wrap !important;
}

body .is-layout-flex > * {
  align-self: flex-start;
  width: 350px;
  padding: 10px;
};

.page-id-147 .wp-block-columns {
  flex-wrap: wrap !important;
  gap: 0 !important;
}
