.exit-intent-popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: rgba(33, 33, 33, 0.8);
  transform: translateY(60%) scale(0);
  transition: transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.exit-intent-popup.visible {
  transform: translateY(0) scale(1);
}

.newsletter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Popup-specific styles */
.exit-intent-popup .newsletter {
  background: #FFF;
  padding: 30px;
  border-radius: 3px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 10px;
  height: 10px;
}